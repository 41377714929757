﻿/* -----------------------------------
 * Nel main.js aggiungi:

import { showPassword, showError, showLoading } from 'custom.js';

ovviamente importi solo le funzioni che effettivamente ti servono e poi le richiami all'interno del main.js come una normale funzione o la esponi globalmente : 

window.showLoading = showLoading;

Puoi anche fare una cosa del genere:

import * as Custom from 'custom.js';

In questo caso richiami le funzioni antecedendo il nome dell'alias es:

Custom.showLoading()
--------------------------------------------  */



//Document ready functions
document.addEventListener("DOMContentLoaded", () => {
    closeMenuByNav();

});


// ** FADE OUT FUNCTION **
function fadeOut(el) {
    el.style.opacity = 1;

    setTimeout(() => {
        (function fade() {
            if ((el.style.opacity -= .05) < 0) {
                el.style.display = "none";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }, "800");
};


 // ** FADE OUT WITH CSS TRANSITION ** (transition:opacity 1s ease)
//function fadeOut(el) {
//    setTimeout(() => {
//        el.style.opacity = 0;
//        setTimeout(() => {
//            el.style.display = 'none';
//            el.style.opacity = 1;
//        }, "1000");
//    }, "500");
//};



/* Loading Layer */
export function showLoading() {
    let elem = document.getElementById('loading_layer');
    elem.style.display = 'block';
}

export function hideLoading() {
    let elem = document.getElementById('loading_layer');
    elem.style.opacity = '0';    

    setTimeout(function () {
        elem.style.display = 'none';
    }, 500);
}


/* scrollToId */
export function scrollToId(id) {
    document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
    });
}

/* scrollTo id minus header */
export function scrollToElm(id, top) {
    let element = document.getElementById(id);
    let headerOffset = (top) ? top : 150;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}


//Close Bootstrap Menu on hamburger click
function closeMenu() {
    let btn = document.querySelector('button.navbar-toggler');

    btn.addEventListener('click', (evt) => {
        let navbar = document.getElementById('navbarNav');
        navbar.classList.toggle('show');
    });
}

//Close Bootstrap Menu on navlink click
function closeMenuByNav() {
    Array.from(document.querySelectorAll('.nav-link'))
        .forEach((el) => el.addEventListener('click', (evt) => {
            let navbar = document.getElementById('navbarNav');
            navbar.classList.remove('show');

            document.querySelector('button.navbar-toggler').classList.add('collapsed'); 
        }));
}


//scrolla fino al primo error message
export function showError() {
    let errorsArray = document.querySelectorAll('.form-error');

    for (let errorLabel of errorsArray) {
        if (errorLabel.isvalid == false) {
            scrollToElm(errorLabel.id);
            break;
        }
    }
}

// ------------------------//
//Accessibility functions
//-------------------------//
export function invalidAccessibility() {
    let inputArray = document.querySelectorAll('input[aria-describedby]');
    for (let input of inputArray) {
        input.removeAttribute('aria-invalid');

        let attribute = input.getAttribute('aria-describedby');

        let errorsArray = document.querySelectorAll('.form-error');

        for (let errorLabel of errorsArray) {
            if (errorLabel.isvalid == false) {
                if (attribute.indexOf(errorLabel.id) > -1) {
                    input.setAttribute('aria-invalid', true);
                }
            }
        }
    }
}

//On input change aria-valid
//document.addEventListener("DOMContentLoaded", () => {

//    // Accessibility checkbox
//    let check = document.getElementById('chkRegolamentoPrivacy');
//    check.setAttribute('required', true);
//    check.setAttribute('aria-required', true);
//    check.setAttribute('aria-describedby', 'Required__Privacy');

//    Array.from(document.querySelectorAll('input[aria-describedby]'))
//        .forEach((element) => element.addEventListener('change', (evt) => {
//            element.removeAttribute('aria-invalid');

//            let attribute = element.getAttribute('aria-describedby');
//            let attArray = attribute.split(' ');
//            for (let attribute of attArray) {
//                let errorLabel = document.getElementById(attribute);

//                if (errorLabel.isvalid == false) {
//                    element.setAttribute('aria-invalid', true);
//                }
//            }
//        }));
//});


//Mostra pssw al click su icona-occhio
export function showPassword() {
    Array.from(document.querySelectorAll('.eyes'))
        .forEach((element) => element.addEventListener('click', (evt) => {
            let pswBox = element.nextElementSibling;

            if (element.classList.contains('fa-eye')) {
                element.classList.remove('fa-eye');
                element.classList.add('fa-eye-slash');
                pswBox.type = "text";
            }
            else {
                element.classList.add('fa-eye');
                element.classList.remove('fa-eye-slash');
                pswBox.type = "password";
            }
        }));
}


/* Show Modal */
//export function showModal() {
//    const errorModal = new Modal(document.getElementById('popErrorModal'))
//    errorModal.show();
//}